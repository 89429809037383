<template>
  <div>
    <flixDashboard>
      <template v-slot:content>
        <div class="flix-container-fluid">
          <flixCheckPermissions role="customer" permission="1" error>
            <bookingCalendar />
          </flixCheckPermissions>
        </div>
      </template>
    </flixDashboard>
  </div>
</template>

<script>
export default {
  components: {
    bookingCalendar () { return import('@/components/bookingCalendar') }
  }
}
</script>
